export const ITEM_MODE_INVENTORY = "Inventory";

export const RECEIVING_MODE_SOURCE_TYPE = "Receiving";

export const MANIFEST_ITEM_DOWNLOAD_TYPES = [
  {
    value: "ManifestWithPhotos",
    label: "Manifest with Photos",
  },
  {
    value: "ManifestOnly",
    label: "Manifest Only",
  },
  {
    value: "FeaturedOnly",
    label: "Featured Items",
  },
];
export const OUTGOING_TRUCK_DOWNLOAD_TYPES = [
  {
    value: "InternalUse",
    label: "Internal Use",
  },
  {
    value: "CustomerFacing",
    label: "Customer Facing",
  },
];
export const OUTGOING_TRUCK_DOWNLOAD_FORMATS = [
  {
    value: "PDF",
    label: "PDF",
  },
  {
    value: "CSV",
    label: "CSV",
  },
];
export const PROCESSING_FROM_VALUE_MAP = {
  Manifest: "Manifest",
  Receiving: "Receiving",
  Warehouse: "Warehouse",
  FullInfoNewAddition: "Inventory Item Addition",
  Restock: "Restock",
};

export const DELIVERY_LOAD_COST_ALLOCATION_TYPE = [
  {
    value: "DynamicRetailPriceCostAllocation",
    label: "Dynamic Retail Price Cost Allocation",
  },
  {
    value: "ItemCountCostAllocation",
    label: "Item Count Cost Allocation",
  },
];

export const PALLET_TYPES = [
  {
    value: "IncomingPallet",
    label: "Incoming Pallet",
  },
  {
    value: "NewPallet",
    label: "New Pallet",
  },
];

export const DELIVERY_LOAD_STATUS = [
  {
    value: "AwaitingArrival",
    label: "Awaiting Arrival",
  },
  {
    value: "Unloaded",
    label: "Unloaded",
  },
];
export const OUTGOING_TRUCK_LOAD_STATUS = [
  {
    value: "Unloaded",
    label: "Unloaded",
  },
  {
    value: "Loaded",
    label: "Loaded",
  },
  {
    value: "PickedUp",
    label: "Picked Up",
  },
];

export const ITEM_FIELD_CONFIG_USER_DISPLAYABLE_NAME_MAP = {
  title: "Title",
  ean: "EAN",
  gtin: "GTIN",
  elid: "ELID",
  description: "Description",
  brand: "Brand",
  model: "Model",
  color: "Color",
  size: "Size",
  dimension: "Dimension",
  weight: "Weight",
  currency: "Currency",
  lowest_recorded_price: "Lowest Recorded Price",
  highest_recorded_price: "Highest Recorded Price",
  images: "Images",
  offers: "Offers",
  user_data: "User Data",
  offerprice: "Retail Price",
  unspecified_condition_sidebar_price: "Unspecified Condition Sidebar Price",
  buy_new_right_top_sidebar: "Buy New Right Top Sidebar",
  buy_used_right_top_sidebar: "Buy Used Right Top Sidebar",
  other_sellers_on_amazon_price: "Other Sellers on Amazon Price",
  new_and_used_price: "New and Used Price",
  list_amazon_price: "List Amazon Price",
  official_amazon_price: "Official Amazon Price",
  asin: "ASIN",
  offersource: "Source",
  offer_sale_price: "Offer Sale Price",
  lpn: "LPN",
  fnsku: "FNSKU",
  original_title: "Original Title",
  m_length: "Sizing Info - Length",
  m_width: "Sizing Info - Width",
  m_height: "Sizing Info - Height",
  m_weight: "Sizing Info - Weight",
  m_dimension: "Sizing Info - Dimension",
  m_weight_unit: "Sizing Info - Weight Unit",
  vendor_code: "Vendor Code",
  m_size: "Sizing Info - Size",
  estimate_price: "Estimate Price",
  is_damaged: "Is Damaged",
  damage_description: "Damage Description",
  in_packaging: "In Packaging",
  is_assembly_required: "Is Assembly Required",
  star_rating: "Star Rating",
  is_missing_parts: "Is Missing Parts",
  missing_parts_description: "Missing Parts Description",
  is_functional: "Is Functional",
  is_received: "Is Received",
  inventory_aisle: "Inventory Aisle",
  inventory_shelf: "Inventory Shelf",
  inventory_row: "Inventory Row",
  bucket: "Bucket",
  auction_starting_price: "Auction Starting Price",
  auction_reserve_price: "Auction Reserve Price",
  location: "Location",
  cost: "Cost",
  shipping_rate: "Shipping Rate",
  shipping_option: "Shipping Option",
  discount_sale_price: "Discount Price",
  department: "Department",
  notes: "Notes",
  category: "Category",
  product_condition: "Condition",
  auction_name: "Auction Name",
  upc_hibid_format: "UPC Hibid Format",
  price: "Price",
  lot: "Lot",
  qty: "Quantity",
  condition: "Condition",
  upc: "UPC",
  username: "User Name",
  rasmus_photos: "Rasmus Photos",
  image_links: "Image Links",
  auction_item_id: "Manifest Item ID",
  user_defined_field_one: "User Defined Field (1)",
  user_defined_field_two: "User Defined Field (2)",
  user_defined_field_three: "User Defined Field (3)",
  user_defined_field_four: "User Defined Field (4)",
  user_defined_field_five: "User Defined Field (5)",
  user_defined_field_six: "User Defined Field (6)",
  user_defined_field_seven: "User Defined Field (7)",
  user_defined_field_eight: "User Defined Field (8)",
  user_defined_field_nine: "User Defined Field (9)",
  user_defined_field_ten: "User Defined Field (10)",
  user_defined_field_eleven: "User Defined Field (11)",
  user_defined_field_twelve: "User Defined Field (12)",
  user_defined_field_thirteen: "User Defined Field (13)",
  user_defined_field_fourteen: "User Defined Field (14)",
  user_defined_field_fifteen: "User Defined Field (15)",
  user_defined_field_sixteen: "User Defined Field (16)",
  user_defined_field_seventeen: "User Defined Field (17)",
  user_defined_field_eighteen: "User Defined Field (18)",
  user_defined_field_nineteen: "User Defined Field (19)",
  user_defined_field_twenty: "User Defined Field (20)",
  product_type: "Product Type",
  is_sold: "Is Sold?",
  vendor_id: "Vendor",
  delivery_load_id: "Delivery Load",
  inventory_group_id: "Inventory Group",
  manifest_group_id: "Manifest Group",
  user_created_by_id: "Created By",
  number_of_items: "Number of Items",
  pallet_number: "Pallet Number",
  condition_id: "Condition",
  department_id: "Department",
  category_id: "Category",
  shelf_id: "Shelf",
  bin_id: "Bin",
  building_id: "Building",
  outgoing_truck_id: "Outgoing Truck",
  retail_price: "Retail Price",
  created_at: "Created At",
  modified_at: "Modified At",
  load_status: "Load Status",
  is_closed: "Is Closed?",
  location_id: "Location",
  name: "Name",
  destination: "Destination",
  sale_price: "Sale Price",
  load_date: "Load Date",
  depart_date: "Depart Date",
  total_pallet_cost: "Total Pallet Cost",
  total_item_cost: "Total Item Cost",
  zone_id: "Zone",
  product_cost: "Product Cost",
  freight_cost: "Freight Cost",
  number_of_pallets: "Number of Pallets",
  load_started_at: "Load Started At",
  load_finished_at: "Load Finished At",
  delivery_company: "Delivery Company",
  delivery_person: "Delivery Person",
  retailer_item_url: "Retailer Item URL",
  ebay_id: "eBay ID",
  ebay_reference_id: "eBay Reference ID",
  internal_sku: "Internal SKU",
};

const userTypeLookup = {
  a: "Admin",
  u: "AccountAdmin",
  e: "Employee",
};

export const GetUserType = (type) => {
  return userTypeLookup[type];
};
