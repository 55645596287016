export const REGISTER_API_PATH = "register/new-account";

export const LOGIN_API_PATH = "auth--dummy-suffix-url/login";
export const AUTH_USER_API_PATH = "auth--dummy-suffix-url/auth-user";

export const LOCATIONS_API_PATH = "inventory/location";
export const GET_ALL_LOCATIONS_API_PATH = `${LOCATIONS_API_PATH}/all`;
export const GET_SINGLE_LOCATION_API_PATH = `${LOCATIONS_API_PATH}?id={locationId}`;
export const ADD_LOCATIONS_API_PATH = `${LOCATIONS_API_PATH}/add`;
export const EDIT_LOCATION_API_PATH = `${LOCATIONS_API_PATH}/edit?id={locationId}`;
export const DELETE_LOCATION_API_PATH = `${LOCATIONS_API_PATH}?id={locationId}`;

export const BUILDINGS_API_PATH = "inventory/building";
export const GET_ALL_BUILDINGS_API_PATH = `${BUILDINGS_API_PATH}/all`;
export const GET_SINGLE_BUILDING_API_PATH = `${BUILDINGS_API_PATH}?id={buildingId}`;
export const ADD_BUILDINGS_API_PATH = `${BUILDINGS_API_PATH}/add`;
export const EDIT_BUILDING_API_PATH = `${BUILDINGS_API_PATH}/edit?id={buildingId}`;
export const DELETE_BUILDING_API_PATH = `${BUILDINGS_API_PATH}?id={buildingId}`;

export const SHELVES_API_PATH = "inventory/shelf";
export const GET_ALL_SHELVES_API_PATH = `${SHELVES_API_PATH}/all`;
export const GET_SINGLE_SHELF_API_PATH = `${SHELVES_API_PATH}?id={shelfId}`;
export const ADD_SHELVES_API_PATH = `${SHELVES_API_PATH}/add`;
export const EDIT_SHELF_API_PATH = `${SHELVES_API_PATH}/edit?id={shelfId}`;
export const DELETE_SHELF_API_PATH = `${SHELVES_API_PATH}?id={shelfId}`;

export const ZONES_API_PATH = "inventory/zone";
export const GET_ALL_ZONES_API_PATH = `${ZONES_API_PATH}/all`;
export const GET_SINGLE_ZONE_API_PATH = `${ZONES_API_PATH}?id={zoneId}`;
export const ADD_ZONES_API_PATH = `${ZONES_API_PATH}/add`;
export const EDIT_ZONE_API_PATH = `${ZONES_API_PATH}/edit?id={zoneId}`;
export const DELETE_ZONE_API_PATH = `${ZONES_API_PATH}?id={zoneId}`;

export const BINS_API_PATH = "inventory/bin";
export const GET_ALL_BINS_API_PATH = `${BINS_API_PATH}/all`;
export const GET_SINGLE_BIN_API_PATH = `${BINS_API_PATH}?id={binId}`;
export const ADD_BINS_API_PATH = `${BINS_API_PATH}/add`;
export const EDIT_BIN_API_PATH = `${BINS_API_PATH}/edit?id={binId}`;
export const DELETE_BIN_API_PATH = `${BINS_API_PATH}?id={binId}`;

export const PRODUCTS_API_PATH = "inventory/product-item";
export const GET_ALL_PRODUCTS_API_PATH = `${PRODUCTS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}?id={productId}`;
export const ADD_PRODUCTS_API_PATH = `${PRODUCTS_API_PATH}/add`;
export const EDIT_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/edit?id={productId}`;
export const DELETE_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}?id={productId}`;
export const APPLY_DISCOUNT_PRICE_TO_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/apply-discounted-price?product_item_id={productId}&discount_price={discountPrice}`;
export const CHANGE_INVENTORY_GROUP_FOR_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/change-inventory-group?product_item_id={productId}&inventory_group_id={inventoryGroupId}`;
export const CHANGE_DELIVERY_LOAD_FOR_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/change-delivery-load?product_item_id={productId}&delivery_load_id={deliveryLoadId}`;
export const MARK_PRODUCT_AS_SOLD_API_PATH = `${PRODUCTS_API_PATH}/mark-as-sold?id={productId}`;
export const MARK_PRODUCT_AS_DAMAGED_API_PATH = `${PRODUCTS_API_PATH}/mark-as-damaged?id={productId}`;
export const TRANSFER_FROM_ANY_TO_RESTOCK_API_PATH = `${PRODUCTS_API_PATH}/transfer-from-any-to-restock?product_item_id={productId}&building_id={buildingId}`;
export const TRANSFER_FROM_INVENTORY_TO_INVENTORY_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/transfer-from-inventory-to-inventory?product_item_id={productId}&building_id={buildingId}&shelf_id={shelfId}`;
export const TRANSFER_FROM_INVENTORY_TO_MANIFEST_PRODUCT_API_PATH = `${PRODUCTS_API_PATH}/transfer-from-inventory-to-manifest?product_item_id={productId}&manifest_id={manifestId}&lot={lot}`;
export const CHANGE_PALLET_FOR_INVENTORY_ITEM_API_PATH = `${PRODUCTS_API_PATH}/change-pallet?id={productId}&pallet_id={palletId}`;

export const CATEGORIES_API_PATH = "category";
export const GET_ALL_CATEGORIES_API_PATH = `${CATEGORIES_API_PATH}/all`;
export const GET_SINGLE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={productCategoryId}`;
export const ADD_CATEGORIES_API_PATH = `${CATEGORIES_API_PATH}/add`;
export const EDIT_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}/edit?id={productCategoryId}`;
export const DELETE_CATEGORY_API_PATH = `${CATEGORIES_API_PATH}?id={productCategoryId}`;

export const DEPARTMENTS_API_PATH = "department";
export const GET_ALL_DEPARTMENTS_API_PATH = `${DEPARTMENTS_API_PATH}/all`;
export const GET_SINGLE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;
export const ADD_DEPARTMENTS_API_PATH = `${DEPARTMENTS_API_PATH}/add`;
export const EDIT_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}/edit?id={departmentId}`;
export const DELETE_DEPARTMENT_API_PATH = `${DEPARTMENTS_API_PATH}?id={departmentId}`;

export const PRODUCT_CONDITIONS_API_PATH = "product-condition";
export const GET_ALL_PRODUCT_CONDITIONS_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;
export const ADD_PRODUCT_CONDITIONS_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/add`;
export const EDIT_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}/edit?id={productConditionId}`;
export const DELETE_PRODUCT_CONDITION_API_PATH = `${PRODUCT_CONDITIONS_API_PATH}?id={productConditionId}`;

export const DELIVERY_LOADS_API_PATH = "inventory/delivery-load";
export const GET_ALL_DELIVERY_LOADS_API_PATH = `${DELIVERY_LOADS_API_PATH}/all`;
export const GET_SINGLE_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}?id={deliveryLoadId}`;
export const GET_COST_ALLOCATION_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}/cost-allocation-improved?id={deliveryLoadId}`;
export const GET_PROFIT_CALCULATION_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}/profit-calculation?id={deliveryLoadId}`;
export const ADD_DELIVERY_LOADS_API_PATH = `${DELIVERY_LOADS_API_PATH}/add`;
export const EDIT_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}/edit?id={deliveryLoadId}`;
export const SET_IS_CLOSED_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}/set-is-closed?id={deliveryLoadId}&is_closed={isClosed}`;
export const CLOSE_PRECHECK_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}/close-precheck?id={deliveryLoadId}`;
export const DELETE_DELIVERY_LOAD_API_PATH = `${DELIVERY_LOADS_API_PATH}?id={deliveryLoadId}`;
export const DELETE_DELIVERY_LOAD_FILE_API_PATH = `${DELIVERY_LOADS_API_PATH}/uploaded-document?id={deliveryLoadId}&file_id={fileId}`;

export const VENDORS_API_PATH = "vendor";
export const GET_ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/all`;
export const GET_SINGLE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;
export const ADD_VENDORS_API_PATH = `${VENDORS_API_PATH}/add`;
export const EDIT_VENDOR_API_PATH = `${VENDORS_API_PATH}/edit?id={vendorId}`;
export const DELETE_VENDOR_API_PATH = `${VENDORS_API_PATH}?id={vendorId}`;

export const INTEGRATIONS_API_PATH = "data-integration";
export const GET_ALL_INTEGRATIONS_API_PATH = `${INTEGRATIONS_API_PATH}/all`;

export const PRODUCT_DISCOUNT_RULES_API_PATH =
  "inventory/product-discount-rule";
export const GET_ALL_PRODUCT_DISCOUNT_RULES_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/all`;
export const GET_SINGLE_PRODUCT_DISCOUNT_RULE_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}?id={productDiscountRuleId}`;
export const ADD_PRODUCT_DISCOUNT_RULES_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/add`;
export const EDIT_PRODUCT_DISCOUNT_RULE_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/edit?id={productDiscountRuleId}`;
export const DELETE_PRODUCT_DISCOUNT_RULE_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}?id={productDiscountRuleId}`;
export const GET_PRODUCT_DISCOUNT_RULES_THAT_APPLY_API_PATH = `${PRODUCT_DISCOUNT_RULES_API_PATH}/discounted-prices-for-inventory-item?product_item_id={productItemId}`;

export const INVENTORY_GROUPS_API_PATH = "inventory/inventory-group";
export const GET_ALL_INVENTORY_GROUPS_API_PATH = `${INVENTORY_GROUPS_API_PATH}/all`;
export const GET_SINGLE_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}?id={inventoryGroupId}`;
export const GET_PALLETS_AND_ITEMS_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}/pallets-and-items?id={inventoryGroupId}`;
export const ADD_INVENTORY_GROUPS_API_PATH = `${INVENTORY_GROUPS_API_PATH}/add`;
export const EDIT_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}/edit?id={inventoryGroupId}`;
export const DELETE_INVENTORY_GROUP_API_PATH = `${INVENTORY_GROUPS_API_PATH}?id={inventoryGroupId}`;

export const OUTGOING_TRUCKS_API_PATH = "inventory/outgoing-truck";
export const GET_ALL_OUTGOING_TRUCKS_API_PATH = `${OUTGOING_TRUCKS_API_PATH}/all`;
export const GET_SINGLE_OUTGOING_TRUCK_API_PATH = `${OUTGOING_TRUCKS_API_PATH}?id={outgoingTruckId}`;
export const ADD_OUTGOING_TRUCKS_API_PATH = `${OUTGOING_TRUCKS_API_PATH}/add`;
export const EDIT_OUTGOING_TRUCK_API_PATH = `${OUTGOING_TRUCKS_API_PATH}/edit?id={outgoingTruckId}`;
export const SET_IS_CLOSED_OUTGOING_TRUCK_API_PATH = `${OUTGOING_TRUCKS_API_PATH}/set-is-closed?id={outgoingTruckId}&is_closed={isClosed}`;
export const GET_PROFIT_CALCULATION_OUTGOING_TRUCK_API_PATH = `${OUTGOING_TRUCKS_API_PATH}/profit-calculation?id={outgoingTruckId}`;
export const DELETE_OUTGOING_TRUCK_API_PATH = `${OUTGOING_TRUCKS_API_PATH}?id={outgoingTruckId}`;
export const DELETE_OUTGOING_TRUCK_FILE_API_PATH = `${OUTGOING_TRUCKS_API_PATH}/uploaded-document?id={outgoingTruckId}&file_id={fileId}`;

export const MANIFESTS_API_PATH = "manifest";
export const GET_ALL_MANIFESTS_API_PATH = `${MANIFESTS_API_PATH}/all`;
export const GET_SINGLE_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}?id={manifestId}`;
export const GET_LOT_SECTION_FOR_MANIFEST_API_PATH = `${MANIFESTS_API_PATH}/check-last-lot?id={manifestId}`;

export const MANIFEST_GROUPS_API_PATH = "manifest-group";
export const GET_ALL_MANIFEST_GROUPS_API_PATH = `${MANIFEST_GROUPS_API_PATH}/all`;
export const GET_SINGLE_MANIFEST_GROUP_API_PATH = `${MANIFEST_GROUPS_API_PATH}?id={manifestGroupId}`;

export const MANIFEST_ITEM_API_PATH = "manifest-item";
export const GET_ALL_MANIFEST_ITEMS_API_PATH = `${MANIFEST_ITEM_API_PATH}/all`;
export const GET_SINGLE_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEM_API_PATH}?id={manifestItemId}`;
export const UPDATE_PRICING_MANIFEST_ITEM_API_PATH = `${MANIFEST_ITEM_API_PATH}/update-pricing?id={manifestItemId}`;

export const COMMON_API_PATH = "inventory/common";
export const GET_INVENTORY_ITEMS_SUPPORTING_DATA_API_PATH = `${COMMON_API_PATH}/get-supporting-data-inventory-items`;
export const GET_MANIFESTS_SUPPORTING_DATA_API_PATH = `${COMMON_API_PATH}/get-supporting-data-manifests`;

export const PRODUCT_INVENTORY_RECORD_API_PATH =
  "inventory/product-inventory-record";
export const GET_HISTORY_FOR_INVENTORY_PRODUCT_ITEM_API_PATH = `${PRODUCT_INVENTORY_RECORD_API_PATH}/all-simple-history?product_item_ids={inventoryItemId}`;

export const PALLETS_API_PATH = "inventory/pallet";
export const GET_ALL_PALLETS_API_PATH = `${PALLETS_API_PATH}/all`;
export const GET_ALL_PALLETS_UNPAGINATED_API_PATH = `${PALLETS_API_PATH}/all-unpaginated`;
export const GET_SINGLE_PALLET_API_PATH = `${PALLETS_API_PATH}?id={palletId}`;
export const ADD_PALLETS_API_PATH = `${PALLETS_API_PATH}/add`;
export const EDIT_PALLET_API_PATH = `${PALLETS_API_PATH}/edit?id={palletId}`;
export const MARK_PALLET_AS_DAMAGED_API_PATH = `${PALLETS_API_PATH}/mark-as-damaged?id={palletId}`;
export const MARK_PALLET_AS_SOLD_API_PATH = `${PALLETS_API_PATH}/mark-as-sold?id={palletId}`;
export const CHANGE_MANIFEST_GROUP_FOR_PALLET_API_PATH = `${PALLETS_API_PATH}/change-manifest-group?id={palletId}`;
export const CHANGE_OUTGOING_TRUCK_FOR_PALLET_API_PATH = `${PALLETS_API_PATH}/change-outgoing-truck?id={palletId}`;
export const TRANSFER_PALLET_TO_MANIFEST_API_PATH = `${PALLETS_API_PATH}/move-to-manifest?id={palletId}&manifest_id={manifestId}&product_condition_id={productConditionId}&lot={lot}&section={section}`;
export const DELETE_PALLET_API_PATH = `${PALLETS_API_PATH}?id={palletId}`;
export const DELETE_PALLET_FILE_API_PATH = `${PALLETS_API_PATH}/uploaded-document?id={palletId}&file_id={fileId}`;

export const DOWNLOADS_API_PATH = "download";
export const MANIFEST_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-manifest`;
export const MANIFEST_GROUP_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-manifest-group`;
export const INVENTORY_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-inventory`;
export const PALLETS_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-pallets`;
export const OUTGOING_TRUCK_DOWNLOAD_API_PATH = `${DOWNLOADS_API_PATH}/download-outgoing-truck`;

export const ACCOUNT_SETTINGS_API_PATH = "account-settings";
export const GET_ZPL_INVENTORY_LABEL_ACCOUNT_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}/inventory-zpl-settings`;
export const EDIT_ZPL_INVENTORY_LABEL_ACCOUNT_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}/edit-inventory-zpl-settings`;
export const GET_ACCOUNT_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}`;
export const EDIT_INVENTORY_SETTINGS_API_PATH = `${ACCOUNT_SETTINGS_API_PATH}/edit-inventory-settings`;

export const PRICE_SETTINGS_API_PATH = "inventory/price-settings";
export const GET_PRICE_SETTINGS_API_PATH = `${PRICE_SETTINGS_API_PATH}`;
export const EDIT_PRICE_SETTINGS_API_PATH = `${PRICE_SETTINGS_API_PATH}/edit`;

export const LABEL_SCAN_API_PATH = "inventory/label-scan";
export const GET_SYSTEM_DEFAULT_LABELS_API_PATH = `${LABEL_SCAN_API_PATH}/system-default-labels`;

export const INVENTORY_PRODUCT_TYPES_API_PATH =
  "inventory/inventory-product-type";
export const GET_ALL_INVENTORY_PRODUCT_TYPES_API_PATH = `${INVENTORY_PRODUCT_TYPES_API_PATH}/all`;
export const GET_SINGLE_INVENTORY_PRODUCT_TYPE_API_PATH = `${INVENTORY_PRODUCT_TYPES_API_PATH}?id={inventoryProductTypeId}`;
export const ADD_INVENTORY_PRODUCT_TYPES_API_PATH = `${INVENTORY_PRODUCT_TYPES_API_PATH}/add`;
export const EDIT_INVENTORY_PRODUCT_TYPE_API_PATH = `${INVENTORY_PRODUCT_TYPES_API_PATH}/edit?id={inventoryProductTypeId}`;
export const DELETE_INVENTORY_PRODUCT_TYPE_API_PATH = `${INVENTORY_PRODUCT_TYPES_API_PATH}?id={inventoryProductTypeId}`;

export const ACCOUNT_API_PATH = "account";
export const GET_INVENTORY_DASHBOARD_API_PATH = `${ACCOUNT_API_PATH}/inventory-dashboards`;

export const INVENTORY_ITEM_FIELD_CONFIG_API_PATH =
  "inventory/inventory-item-field-config";
export const GET_INVENTORY_ITEM_FIELD_CONFIGS_API_PATH = `${INVENTORY_ITEM_FIELD_CONFIG_API_PATH}/all`;
export const UPDATE_ALL_INVENTORY_ITEM_FIELD_CONFIGS_API_PATH = `${INVENTORY_ITEM_FIELD_CONFIG_API_PATH}/edit`;
export const SET_INVENTORY_ITEM_FIELD_CONFIGS_ACCOUNT_SETTINGS_TO_ALL_USERS_API_PATH = `${INVENTORY_ITEM_FIELD_CONFIG_API_PATH}/set-account-settings-to-all-users`;

export const SCAN_SETTING_API_PATH = "scan-setting";
export const GET_SCAN_SETTINGS_API_PATH = `${SCAN_SETTING_API_PATH}`;
export const TOGGLE_DEFAULT_SETTING_API_PATH = `${SCAN_SETTING_API_PATH}/toggle-source?default_setting={defaultSetting}`;
